import React from "react"
import SEO from "../components/SEO"

import styles from "../styles/modules/static-pages.module.scss"

const PrivacyPolicy = () => (
  <>
    <SEO
      title="NeighborShare Terms of Service"
      description="Different stories, shared community"
    />
    <div className="wrapper wrapper--left">
      <h2 className={`x-large-body-copy ${styles.title}`}>
        Privacy policy
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          <i>LAST UPDATED: May 3, 2021</i>
        </div>
      </h2>
      <section className={styles.legalSection}>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          NeighborShare, Inc. (“NS,” “we,” or “us”) wants you to be familiar
          with how we collect, use and disclose information. This Privacy Policy
          describes our practices in connection with information that we collect
          through:
          <ul className={styles.legalList}>
            <li>
              Our websites currently located at{" "}
              <a href="https://nbshare.org/">https://nbshare.org/</a> and any
              other websites operated by us from which you are accessing this
              Privacy Policy (the “Websites”);
            </li>
            <li>
              Our social media pages and apps, including our Instagram located
              at{" "}
              <a href="https://www.instagram.com/neighborshare/">
                https://www.instagram.com/neighborshare/
              </a>{" "}
              (collectively, our “Social Media Pages”); and
            </li>
            <li>
              HTML-formatted email messages that we send to you that link to
              this Privacy Policy or other communications with you.
            </li>
          </ul>
        </div>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          Collectively, we refer to the Websites, Social Media Pages and emails
          as our “Services.”
        </div>
      </section>
      <section className={styles.legalSection}>
        <h3>Personal Information</h3>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          “Personal Information” is information that identifies you as an
          individual or relates to an identifiable individual. The Services
          collect Personal Information, including:
          <ul className={styles.legalList}>
            <li>Your name</li>
            <li>Email address</li>
            <li>
              IP address (we may also derive your approximate location from your
              IP address)
            </li>
            <li>Profile picture</li>
            <li>Social media account ID</li>
          </ul>
        </div>
      </section>
      <section className={styles.legalSection}>
        <h4>Third Party Payment Service</h4>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          We use a third party payment service, such as Stripe, to process
          donations made through the Services. If you wish to make a donation
          through the Services, certain Personal Information (including your
          name, payment card number, expiration date and security code, country
          or region, and postal code) will be collected by such third party and
          not by us, and will be subject to the third party’s privacy policy,
          rather than this Privacy Policy. We do not receive such Personal
          Information and we have no control over, and are not responsible for,
          this third party’s collection, use, and disclosure of your Personal
          Information.
        </div>
      </section>
      <section className={styles.legalSection}>
        <h4>Collection of Personal Information</h4>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          We and our service providers collect Personal Information in a variety
          of ways, including:
          <ul className={styles.legalList}>
            <li>
              <span className={`underline`}>Through the services.</span> We
              collect Personal Information through the Services, for example,
              when you sign up for our newsletter.
            </li>
            <li>
              <span className={`underline`}>From Other Sources.</span> We may
              receive your Personal Information from other sources, for example,
              an individual who makes a donation on your behalf.
            </li>
          </ul>
          We need to collect Personal Information in order to provide the
          requested Services to you. If you do not provide the information
          requested, we may not be able to provide the Services. If you disclose
          any Personal Information relating to other people to us or our service
          providers in connection with the Services, you represent that you have
          the authority to do so and to permit us to use the information in
          accordance with this Privacy Policy.
        </div>
      </section>
      <section className={styles.legalSection}>
        <h4>Use of Personal Information</h4>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          We and our service providers use Personal Information for the
          following purposes:
          <ul className={styles.legalList}>
            <li>
              <span className={`underline`}>
                Providing the functionality of the Services and fulfilling your
                requests.
              </span>
              <ul>
                <li>To provide the Services’ functionality to you.</li>
                <li>
                  To respond to your inquiries and fulfill your requests, when
                  you contact us via one of our online contact forms or
                  otherwise.
                </li>
                <li>
                  To complete your donations and provide you with related
                  customer service.
                </li>
                <li>
                  To send administrative information to you, such as changes to
                  our terms, conditions, and policies.
                </li>
              </ul>
            </li>
            <li>
              <span className={`underline`}>
                Providing you with our newsletter and/or other information and
                materials relevant to our mission or organization and
                facilitating social sharing.
              </span>
              <ul>
                <li>
                  To send you emails with information about our services and
                  other information relevant to our mission or organization.
                </li>
                <li>
                  To facilitate social sharing that you choose to use, including
                  through the Social Media Pages.
                </li>
              </ul>
            </li>
            <li>
              <span className={`underline`}>
                Analyzing Personal Information for reporting and providing
                personalized services
              </span>
              <ul>
                <li>
                  To analyze or predict our users’ preferences in order to
                  prepare aggregated trend reports on how our digital content is
                  used, so we can improve our Services.
                </li>
                <li>
                  To better understand your interests and preferences, so that
                  we can personalize our interactions with you and provide you
                  with information and/or offers tailored to your interests.
                </li>
                <li>
                  To better understand your preferences so that we can deliver
                  content via our Services that we believe will be relevant and
                  interesting to you.
                </li>
              </ul>
            </li>
            <li>
              <span className={`underline`}>
                Aggregating and/or anonymizing Personal Information.
              </span>
              <ul>
                <li>
                  We may aggregate and/or anonymize Personal Information so that
                  it will no longer be considered Personal Information. We do so
                  to generate other data for our use, which we may use and
                  disclose for any purpose, as it no longer identifies you or
                  any other individual.
                </li>
              </ul>
            </li>
            <li>
              <span className={`underline`}>
                Accomplishing our business purposes.
              </span>
              <ul>
                <li>For data analysis;</li>
                <li>
                  To address legal, regulatory, or contractual requirements, and
                  for security monitoring purposes and undertaking quality and
                  safety assurance measures;
                </li>
                <li>
                  For maintaining or modifying our current services or
                  developing new services;
                </li>
                <li>
                  For identifying usage trends and determining the effectiveness
                  of our campaigns; and
                </li>
                <li>For operating and expanding our activities.</li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <section className={styles.legalSection}>
        <h4>Disclosure of Personal Information</h4>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          We disclose Personal Information:
          <ul className={styles.legalList}>
            <li>
              <span className={`underline`}>
                To our third party service providers, to facilitate services
                they provide to us.
              </span>
              <ul>
                <li>
                  These can include providers of services such as donation
                  processing, website hosting, data analysis, information
                  technology and related infrastructure provision, customer
                  service, email delivery, auditing, and other services.
                </li>
              </ul>
            </li>
            <li>
              <span className={`underline`}>
                By using the Services, you may elect to disclose Personal
                Information.
              </span>
              <ul>
                <li>
                  On message boards, chat, profile pages, blogs, and other
                  services to which you are able to post information and content
                  (including, without limitation, our Social Media Pages).
                  Please note that any information you post or disclose through
                  these services will become public and may be available to
                  other users and the general public.
                </li>
                <li>
                  Through your social sharing activity. When you connect your
                  social media account with your use of the Services, you may
                  share information with your friends associated with your
                  social media account, with other users and with your social
                  media account provider. By doing so, you authorize us to
                  facilitate this sharing of information, and you understand
                  that the use of shared information will be governed by the
                  social media provider’s privacy policy.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          If you make a donation using the Services, your donation will be
          processed by our third party payment service provider (Stripe), which
          will share your Personal Information with the nonprofit organization
          (our service partner) that provided the Recipient profile and need
          that you selected.
        </div>
      </section>
      <section className={styles.legalSection}>
        <h4>Other Uses and Disclosures</h4>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          We also use and disclose your Personal Information as necessary or
          appropriate, in particular when we have a legal obligation or
          legitimate interest to do so:
          <ul className={styles.legalList}>
            <li>
              <span className={`underline`}>
                To comply with applicable law and regulations.
              </span>
            </li>
            <li>
              <span className={`underline`}>
                To cooperate with public and government authorities.
              </span>
              <ul>
                <li>
                  To respond to a request or to provide information we believe
                  is necessary or appropriate.
                </li>
              </ul>
            </li>
            <li>
              <span className={`underline`}>
                To cooperate with law enforcement.
              </span>
              <ul>
                <li>
                  For example, when we respond to law enforcement requests and
                  orders or provide information we believe is important.
                </li>
              </ul>
            </li>
            <li>
              <span className={`underline`}>For other legal reasons.</span>
              <ul>
                <li>To enforce our terms and conditions; and</li>
                <li>
                  To protect our rights, privacy, safety or property, you or
                  others.
                </li>
              </ul>
            </li>
            <li>
              <span className={`underline`}>
                In connection with a sale or business transaction.
              </span>
              <ul>
                <li>
                  We have a legitimate interest in disclosing or transferring
                  your Personal Information to a third party in the event of any
                  reorganization, transfer, or other disposition of all or any
                  portion of our operations.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <section className={styles.legalSection}>
        <h3>OTHER Information</h3>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          “Other Information” is any information that does not reveal your
          specific identity or does not directly relate to an identifiable
          individual. The Services collect Other Information such as:
          <ul className={styles.legalList}>
            <li>Browser and device information</li>
            <li>
              Information collected through cookies, pixel tags and other
              technologies
            </li>
            <li>
              Demographic information and other information provided by you that
              does not reveal your specific identity
            </li>
            <li>
              Information that has been aggregated in a manner such that it no
              longer reveals your specific identity
            </li>
          </ul>
        </div>
      </section>
      <section className={styles.legalSection}>
        <h4> Collection of Other Information</h4>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          We and our service providers may collect Other Information in a
          variety of ways, including:
          <ul className={styles.legalList}>
            <li>
              <span className={`underline`}>Your browser or device.</span>
              <ul>
                <li>
                  Certain information is collected by most browsers or
                  automatically through your device, such as your Media Access
                  Control (MAC) address, computer type (Windows or Mac), screen
                  resolution, operating system name and version, device
                  manufacturer and model, language and Internet browser type and
                  version. We use this information to ensure that the Services
                  function properly.
                </li>
              </ul>
            </li>
            <li>
              <span className={`underline`}>Cookies.</span>
              <ul>
                <li>
                  Cookies are pieces of information stored directly on the
                  computer that you are using. Cookies allow us to collect
                  information such as browser type, time spent on the Services,
                  pages visited and other traffic data. We and our service
                  providers use the information for security purposes, to
                  facilitate navigation, to display information more
                  effectively, and to personalize your experience. We also
                  gather statistical information about use of the Services in
                  order to continually improve their design and functionality,
                  understand how they are used, and assist us with resolving
                  questions regarding them. Cookies further allow us to select
                  which of our advertisements or offers are most likely to
                  appeal to you and display them while you are on the Services.
                  We may also use cookies or other technologies in online
                  advertising to track responses to our ads. We currently
                  respond to browser do-not-track signals. If you do not want
                  information collected through the use of cookies, most
                  browsers allow you to automatically decline cookies or be
                  given the choice of declining or accepting a particular cookie
                  (or cookies) from a particular website. You may also wish to
                  refer to{" "}
                  <a href="http://www.allaboutcookies.org/manage-cookies/index.html">
                    http://www.allaboutcookies.org/manage-cookies/index.html
                  </a>
                  . If, however, you do not accept cookies, you may experience
                  some inconvenience in your use of the Services. You also may
                  not receive advertising or other offers from us that are
                  relevant to your interests and needs.
                </li>
              </ul>
            </li>
            <li>
              <span className={`underline`}>
                Pixel tags and other similar technologies.
              </span>
              <ul>
                <li>
                  <span className={`underline`}>Pixel tags.</span> Pixel tags
                  (also known as web beacons and clear GIFs) may be used to,
                  among other things, track the actions of users of the Services
                  (including email recipients), measure the success of our
                  marketing campaigns, and compile statistics about usage of the
                  Services and response rates.
                </li>
                <li>
                  <span className={`underline`}>Analytics.</span> We use Google
                  Analytics, which uses cookies and similar technologies to
                  collect and analyze information about use of the Services and
                  report on activities and trends.  This service may also
                  collect information regarding the use of other websites, apps
                  and online resources.  You can learn about Google’s practices
                  by going to{" "}
                  <a href="https://www.google.com/policies/privacy/‌partners/">
                    https://www.google.com/policies/privacy/‌partners
                  </a>
                  , and exercise the opt-out provided by Google by downloading
                  the Google Analytics opt-out browser add-on, available at{" "}
                  <a href="https://tools.google.com/dlpage/gaoptout">
                    https://tools.google.com/dlpage/gaoptout
                  </a>
                  .
                </li>
              </ul>
            </li>
            <li>
              <span className={`underline`}>Physical Location.</span>
              <ul>
                <li>
                  We may collect the physical location of your device by, for
                  example, using satellite, cell phone tower or WiFi signals. We
                  may use your device’s physical location to provide you with
                  personalized location-based services and content. In some
                  instances, you may be permitted to allow or deny such uses of
                  your device’s location, but if you do, we may not be able to
                  provide you with the applicable personalized services and
                  content.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <section className={styles.legalSection}>
        <h4>Uses and Disclosures of Other Information</h4>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          We may use and disclose Other Information for any purpose, except
          where we are required to do otherwise under applicable law. In some
          instances, we may combine Other Information with Personal Information.
          If we do, we will treat the combined information as Personal
          Information as long as it is combined.
        </div>
      </section>
      <section className={styles.legalSection}>
        <h3>Security</h3>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          We seek to use reasonable organizational, technical and administrative
          measures to protect Personal Information within our organization.
          Unfortunately, no data transmission or storage system can be
          guaranteed to be 100% secure. If you have reason to believe that your
          interaction with us is no longer secure, please immediately notify us
          in accordance with the “Contacting Us” section below.
        </div>
      </section>
      <section className={styles.legalSection}>
        <h3>CHOICES AND ACCESS</h3>
        <section className={styles.legalSection}>
          <h4>
            Your choices regarding our use and disclosure of your Personal
            Information
          </h4>
          <div className={`legalese-body-copy ${styles.legalParagraph}`}>
            We give you choices regarding our use and disclosure of your
            Personal Information. You may opt out from:
            <ul className={styles.legalList}>
              <li>
                <span className={`underline`}>Receiving emails from us.</span>{" "}
                If you no longer want to receive emails from us on a
                going-forward basis, you may opt out by clicking the email
                footer link to unsubscribe.
              </li>
            </ul>
          </div>
          <div className={`legalese-body-copy ${styles.legalParagraph}`}>
            We will try to comply with your request(s) as soon as reasonably
            practicable. Please note that if you opt out of receiving emails
            from us, we may still send you important administrative messages,
            from which you cannot opt out.
          </div>
        </section>
        <section className={styles.legalSection}>
          <h4>
            How you can access, change, or delete your Personal Information
          </h4>
          <div className={`legalese-body-copy ${styles.legalParagraph}`}>
            If you would like to request to access, correct, update, or delete
            Personal Information, you may contact us in accordance with the
            “Contacting Us” section below. We will respond to your request
            consistent with applicable law. Please note that we may need to
            retain certain information for recordkeeping purposes and/or to
            complete any requests or donations that you began prior to
            requesting a change or deletion.
          </div>
        </section>
      </section>
      <section className={styles.legalSection}>
        <h3>RETENTION PERIOD</h3>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          We retain Personal Information for as long as needed or permitted in
          light of the purpose(s) for which it was obtained and consistent with
          applicable law.
        </div>
      </section>
      <section className={styles.legalSection}>
        <h3>THIRD PARTY SERVICES</h3>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          This Privacy Policy does not address, and we are not responsible for,
          the privacy, information, or other practices of any third parties,
          including any third party to which we refer you. The inclusion of a
          link on the Services does not imply our endorsement of the linked site
          or service.
        </div>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          In addition, we are not responsible for the information collection,
          use, disclosure, or security policies or practices of other
          organizations, such as Facebook, Instagram, Twitter, LinkedIn or any
          other social media platform provider, including with respect to any
          Personal Information you disclose to other organizations through or in
          connection with our Social Media Pages.
        </div>
      </section>
      <section className={styles.legalSection}>
        <h3>THIRD PARTY ADVERTISING</h3>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          We use third-party advertising companies to serve advertisements
          regarding goods and services that may be of interest to you when you
          access and use the Services and other websites or online services.
        </div>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          You may receive advertisements based on information relating to your
          access to and use of the Services and other websites or online
          services on any of your devices, as well as on information received
          from third parties. These companies place or recognize a unique cookie
          on your browser (including through the use of ixel tags). They also
          use these technologies, along with information they collect about your
          online use, to recognize you across the devices you use, such as a
          mobile phone and a laptop. If you would like more information about
          this practice, and to learn how to opt out of it in desktop and mobile
          browsers on the particular device on which you are accessing this
          Privacy Policy, please visit{" "}
          <a href="http://optout.aboutads.info/#/">
            http://optout.aboutads.info/#/
          </a>{" "}
          and{" "}
          <a href="http://optout.networkadvertising.org/#/">
            http://optout.networkadvertising.org/#/
          </a>
          .
        </div>
      </section>
      <section className={styles.legalSection}>
        <h3>Use of Services by MINORS</h3>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          The Services are not directed to individuals under the age of 18, and
          we do not knowingly collect Personal Information from individuals
          under 18.
        </div>
      </section>
      <section className={styles.legalSection}>
        <h3>Jurisdiction and CROSS-BORDER TRANSFER</h3>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          We are located in the United States. Your Personal Information may be
          stored and processed in the United States and any country where we
          have facilities or in which we engage service providers. By using the
          Services you understand that your information may be transferred to
          countries outside of your country of residence, including the United
          States, which may have data protection rules that are different from
          those of your country. In certain circumstances, courts, law
          enforcement agencies, regulatory agencies or security authorities in
          those other countries may be entitled to access your Personal
          Information.
        </div>
      </section>
      <section className={styles.legalSection}>
        <h3>UPDATES TO THIS PRIVACY POLICY</h3>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          The “Last Updated” legend at the top of this Privacy Policy indicates
          when this Privacy Policy was last revised. Any changes will become
          effective when we post the revised Privacy Policy on the Services. In
          some cases, we may notify you of changes to our Privacy Policy or seek
          your consent to a change.
        </div>
      </section>
      <section className={styles.legalSection}>
        <h3>CONTACTING US</h3>
        <div className={`legalese-body-copy ${styles.legalParagraph}`}>
          NeighborShare, Inc. is the organization responsible for collection,
          use, and disclosure of your Personal Information under this Privacy
          Policy. If you have any questions about this Privacy Policy, please
          contact us at <a href="mailto:help@nbshare.org">help@nbshare.org</a>.
          Because email communications are not always secure, please do not
          include sensitive information in your emails to us.
        </div>
      </section>
    </div>
  </>
)

export default PrivacyPolicy
